import React, { useState, useContext } from "react";
import "./Footer.css";
import FooterSvg from "../../svg/FooterSvg";
import { AppContext } from "../context/Context";
import Facebook from "../../svg/Facebook.svg";
import Instagram from "../../svg/Instagram.svg";
import Youtube from "../../svg/Youtube.svg";

const Footer = () => {
    const { setIsFormOpen } = useContext(AppContext);
    const [value, setValue] = useState([
        {
            title: "Комфорт",
            items: [
                { name: "RAMS city", link: "https://ramscity.kz/" },
                { name: "Navoi 3.0", link: "https://navoi3.kz/" },
                { name: "Nomad 2", link: "https://nomad2.kz/" },
            ],
        },
        {
            title: "Бизнес",
            items: [
                { name: "RAMS Signature", link: "https://rams-signature.kz/" },
                { name: "RAMS Garden", link: "https://ramsgarden.kz/" },
                { name: "RAMS EVO", link: "https://rams-evo.kz/" },
            ],
        },
        {
            title: "Премиум",
            items: [
                { name: "Grande Vie", link: "https://grandevie.kz/" },
                { name: "La Verde", link: "https://laverde.kz/" },
            ],
        },
    ]);

    const ComplexItem = ({ name, link }) => {
        return (
            <a href={link} target="_blank">
                {name}
            </a>
        );
    };

    return (
        <footer>
            <div className="inner">
                <div className="container">
                    <div className="left">
                        <FooterSvg className="logo" />
                        <a target="_blank" href="https://ramsqz.com/ru/home">
                            Корпоративный сайт
                        </a>
                    </div>
                    <div className="right">
                        <div className="top">
                            <div className="addresses">
                                <div className="block">
                                    <h4>Офисы продаж</h4>
                                    <ComplexItem name="г. Алматы, пр. Суюнбая 15б" link="https://go.2gis.com/tge9w" />
                                    <ComplexItem name="г. Алматы, ул. Тимирязева, 37" link="https://go.2gis.com/6m81q" />
                                    <p style={{ fontSize: 14 + "px" }}>
                                        Пн-Пт 10:00-20:00 <br /> Сб-Вс 10:00-19:00
                                    </p>
                                </div>
                                {value.map((block, blockIndex) => (
                                    <div className="block" key={blockIndex}>
                                        <h4>{block.title}</h4>
                                        {block.items.map((item, index) => (
                                            <ComplexItem key={index} name={item.name} link={item.link} />
                                        ))}
                                    </div>
                                ))}
                                <div className="block">
                                    <h4>Контакты</h4>
                                    <a href="tel: +77072124444" className="phone">
                                        +7 707 212 44 44
                                    </a>
                                    <button onClick={() => setIsFormOpen(true)}>Оставить заявку</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <div className="bottom">
                    <p>
                        Архитектура проекта, изображения благоустройства, фасадов, интерьера, применяемых материалов, их нумерация, инфраструктура комплекса являются условными и
                        могут быть изменены в ходе проектирования, строительства и эксплуатации в рамках положительного заключения государственной экспертизы. Настоящая реклама, в
                        соответствии со ст. 395 ГК РК не является публичной офертой.
                    </p>
                    <div className="bottom-links">
                        <a href="https://www.instagram.com/rams_qazaqstan/">
                            <img src={Instagram} alt="instagram" />
                        </a>
                        <a href="https://www.youtube.com/@ramsqazaqstan">
                            <img src={Youtube} alt="youtube" />
                        </a>
                        <a href="https://www.facebook.com/ramspromokaz/">
                            <img src={Facebook} alt="facebook" />
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
